import React from 'react';
import { Link } from 'gatsby';

import aboutStyles from './About.module.scss';

const About = () => {
  const { wrap, content } = aboutStyles;

  return (
    <div className={wrap}>
      <div className={content}>
        <p className="bolder">
          I’m a self-taught software developer and licensed attorney that enjoys
          making things that solve interesting problems.
        </p>
        <p>
          After law school and a short stint practicing at an immigration firm,
          I decided to pursue more creative endeavors outside of the legal
          field. My curiosity and passion for learning led me to the world of
          programming. Since 2017, I’ve dedicated my nights and weekends
          learning how to code and develop applications.
        </p>
        <p>
          I’m passionate about helping startups and solo entrepreneurs build
          websites and other useful services. My work as an engineer is paired
          with a strong sense of{' '}
          <span className="bold-and-italic secondary-color">product</span> — a
          constant focus on{' '}
          <span className="bold-and-italic secondary-color">what</span> it is
          you're building, its defined{' '}
          <span className="bold-and-italic secondary-color">problem space</span>{' '}
          and <span className="bold-and-italic secondary-color">how</span> you
          intend to sell it.
        </p>
        <p>
          Born in Toronto, Canada and raised in Houston, Texas. Happily living
          in New York City.
        </p>
        <p>Available for hire. Resume available upon request.</p>
      </div>
    </div>
  );
};

export default About;
